export const slugs = {

  "classroom": "/classroom",
  "start_class_exam": "/start-class-exam",
  
  "new_book": "/new-book",
  "books_details": "/admin/books/",
  "all_books": "/all-books",
  "db_slug_book": "books",

  "new_chapter_wise_exam": "/new-chapter",
  "chapter_details": "/chapters/",
  "all_chapters": "/all-chapters",
  "db_slug_chapter_wise_exam": "chapter_wise_exam",

  "new_daily_exam": "/new-daily-exam",
  "daily_exam_details": "/daily-exams/",
  "all_daily_exam": "/all-daily-exam",
  "db_slug_daily_exam": "daily_exam",

  "new_japanese_exam": "/new-japanese-exam",
  "japanese_exam_details": "/japanese-exams/",
  "all_japanese_exam": "/all-japanese-exam",

  "new_manufacturing_exam": "/new-manufacturing-exam",
  "manufacturing_exam_details": "/manufacturing-exams/",
  "all_manufacturing_exam": "/all-manufacturing-exam",

  "new_agriculture_exam": "/new-agriculture-exam",
  "agriculture_exam_details": "/agriculture-exams/",
  "all_agriculture_exam": "/all-agriculture-exam",

  "new_ship_building_exam": "/new-ship_building-exam",
  "ship_building_exam_details": "/ship_building-exams/",
  "all_ship_building_exam": "/all-ship_building-exam",

  "new_bridge_course_exam": "/new-bridge_course-exam",
  "bridge_course_exam_details": "/bridge_course-exams/",
  "all_bridge_course_exam": "/all-bridge_course-exam",

  "new_loksewa_exam": "/new-loksewa-exam",
  "loksewa_exam_details": "/loksewa-exams/",
  "all_loksewa_exam": "/all-loksewa-exam",

  "new_german_exam": "/new-german-exam",
  "german_exam_details": "/german-exams/",
  "all_german_exam": "/all-german-exam",
  
  "new_hebrew_exam": "/new-hebrew-exam",
  "hebrew_exam_details": "/hebrew-exams/",
  "all_hebrew_exam": "/all-hebrew-exam",

  "new_demo_exam": "/new-demo-exam",
  "demo_exam_details": "/demo-exams/",
  "all_demo_exam": "/all-demo-exam",
  "db_slug_demo_exam": "demo_exam",

  "new_buy_exam": "/new-student-exam",
  "buy_exam_details": "/student-exams/",
  "all_buy_exam": "/all-student-exam",
  "db_slug_buy_exam": "paid_exam",

  "new_batch_order": "/new-batch-order",
  "batch_order_details": "/batch-order/",
  "all_batch_orders": "/all-batch-order",
  "db_slug_batch_orders": "batch_order",

  "new_exam": "/new-exam",
  "exam_details": "/exams/",
  "all_exam": "/all-exam",
  // "db_slug_paid_exam": "paid_exam",
  "db_slug_paid_exam": "paid_exam",
  "result_admin_view":"/result-admin-view",
  "chapter_result_admin_view":"/chapter-result-admin-view",

  "result_details": "/results/",
  "all_results": "/all-results",
  "all_chapter_results": "/all-chapter-results",
  "db_slug_paid_results": "daily_results",
  "db_slug_buy_results": "daily_results",

  "daily_result_details": "/daily-results/",
  "all_daily_results": "/all-daily-result",
  "db_slug_daily_results": "daily_results",

  "chapter_wise_result_details": "/chapter-wise-results/",
  "all_chapter_wise_results": "/all-chapter-wise-result",
  "db_slug_chapter_wise_results": "chapter_wise_results",

  "new_student": "/new-student",
  "student_details": "/students/",
  "all_students": "/all-students",
  "db_slug_user_data": "user_data",

  "new_batch": "/new-batch",
  "batch_details": "/batches/",
  "all_batch": "/all-batches",
  "db_slug_batch": "exam_packages",
  
  "new_chapter": "/new-chapter",
  "chapter_details": "/chapters/",
  "all_chapter": "/all-chapters",
  "db_slug_chapters": "chapter_wise_course",

  "new_teacher": "/new-teacher",
  "teacher_details": "/teachers/",
  "all_teachers": "/all-teachers",

  "exam": "/all-exams-list",
  "demo_exam": "/all-demo-exam-list",
  "chapter_wise_exam": "/all-chapters-list",
  "daily_exam": "/all-daily-exam-list",
  "batch_payment": "/batch-payment",

  "start_exam": "/start-exam",
  "start_demo_exam": "/start-demo-exam",
  "start_chapter_wise_exam": "/start-chapter-exam",
  "start_daily_exam": "/start-daily-exam",

  "exam_result_list": "/my-exam-results",
  "daily_exam_result_list": "/my-daily-results",
  "chapter_wise_exam_result_list": "/my-chapterwise-results",

  "exam_result": "/exam-result",
  "daily_exam_result": "/daily-exam-result",
  "chapter_exam_result": "/chapter-exam-result",

  "daily_exam_result_list": "/my-daily-results",

  
  "books": "/all-books-list",
  "book_details_student": "/book-details",
}